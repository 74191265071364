<template>
  <div>
    <h1 class="page-title">Update Ticket</h1>

    <div v-if="loading || !formLoaded">
      <span class="spinner"></span>
    </div>
    <div v-if="!loading && formLoaded">
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Resolved
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-status"
            placeholder="Select status"
            v-model="resolved"
            :options="['True', 'False']"
          ></multiselect>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Current Status
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-status"
            placeholder="Select status"
            v-model="currentStatus"
            :options="['PENDING', 'ACKNOWLEDGED', 'IN PROGRESS', 'WAITING FOR USER RESPONSE', 'RESOLVED']"
          ></multiselect>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Complaint Source
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-complaint-source"
            type="text"
            v-model="complaintSource"
            disabled
            placeholder="Enter complaint source"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Issue Priority
            <span class="text-danger">*</span>
          </template>

          <multiselect
            id="input-status"
            placeholder="Select Priority"
            v-model="priority"
            disabled
            :options="['LOW', 'MEDIUM', 'HIGH']"
          ></multiselect>
        </b-form-group>

        <b-form-group id="group-product" label-for="input-product">
          <template v-slot:label>
            Estimated Time
            <span class="text-danger">*</span>
          </template>

          <b-form-input
            id="input-escalation-time"
            type="text"
            v-model="escalationTime"
            placeholder="Enter estimated time"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <b-row class="mt-2">
            <b-col class="text-right">
              <b-button type="submit" size="sm" variant="success" :disabled="loading">
                <span class="spinner spinner-white" v-if="loading"></span>
                <font-awesome-icon :icon="['fas', 'save']" class="mr-1" />Save
              </b-button>
              <b-button class="ml-2" size="sm" variant="warning" :to="{ path: `/complain` }">
                <font-awesome-icon :icon="['fas', 'long-arrow-alt-left']" class="mr-1" />Back to list
              </b-button>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import moment from 'moment';

export default {
  name: 'ModifyTicket',
  components: {
    Multiselect
  },
  data() {
    return {
      formLoaded: true,
      loading: false,
      currentStatus: '',
      resolved: '',
      complaintSource: '',
      priority: '',
      escalationTime: ''
    };
  },
  mounted() {
    this.getOne({ id: this.$route.params.id });
  },
  computed: {
    ...mapState('contactUs', ['ticket'])
  },
  methods: {
    ...mapActions('contactUs', ['getOne', 'patchOne']),

    onSubmit() {
      const ticket = {
        name: this.ticket.name,
        mobile: this.ticket.mobile,
        email: this.ticket.email,
        issue: this.ticket.issue,
        details: this.ticket.details,
        storeid: this.ticket.storeid,
        complainNumber: this.ticket.complainNumber,
        imageUrl: this.ticket.imageUrl,
        complainSource: this.ticket.complainSource,
        currentStatus: this.currentStatus,
        isResolved: this.resolved === 'True' ? true : false, // eslint-disable-line
        issuePriority: this.priority,
        reslovedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
        escalationTime: this.escalationTime
      };
      this.patchOne({ id: this.$route.params.id, ticket });
      this.$router.push('/complain');
    }
  },
  watch: {
    ticket(newV) {
      if (newV) {
        this.currentStatus = newV.currentStatus;
        this.resolved = newV.isResolved === true ? 'True' : 'False';
        this.complaintSource = newV.complainSource;
        this.priority = newV.issuePriority;
        this.escalationTime = newV.escalationTime;
      }
    }
  }
};
</script>
<style src="vue-search-select/dist/VueSearchSelect.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


